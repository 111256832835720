import { Controller } from "@hotwired/stimulus"
import { scrollToElement } from '../helpers'

// Connects to data-controller="referenced-discussion"
export default class extends Controller {
  static targets = ["list", "details", "backButton"]

  handleLoad() {
    if (this.detailsTarget.innerHTML.trim()) {
      this.listTarget.classList.add('hidden')
      this.backButtonTarget.classList.remove('hidden')
    } else {
      this.listTarget.classList.remove('hidden')
      this.backButtonTarget.classList.add('hidden')
    }

    scrollToElement(this.element)
  }

  clearFrame() {
    this.detailsTarget.innerHTML = ''
    this.handleLoad()
  }
}
